<template>
  <div v-if="item" class="p-d-flex p-ai-center">
    <Spinner v-if="showUpdatedItemPdfSpinner && item.state !== 1 && item.state !== 7 && disableEditButton && isItemUpdated" :isSmall="true" spinnerStyle="width: 20px; height: 20px"></Spinner>
    <a @contextmenu.prevent v-else-if="item.state !== 1 && item.state !== 7 && item.file_path" href="#" draggable="false" @click.prevent="getPdf(item.file_path)" class="p-link table-link-icon">
      <img src="/assets/images/pdf_sm_icon.gif" draggable="false" alt="pdf_sm" class="pdf-icon"/>
    </a>
  </div>
</template>

<script>
import httpClient from "@/services/http.services";
// import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  name: 'SmallPdfButton',
  mixins: [showErrorsMixins],
  // data() {
  //   return {
  //     pdfIsLoading: false
  //   }
  // },
  props: {
    item: Object,
    disableEditButton: Boolean,
    isItemUpdated: Boolean,
    showUpdatedItemPdfSpinner: {
      type: Boolean,
      default: false,
    }
  },
  // watch: {
  //   'item.updatePdf'(value) {
  //     if (value) {
  //       pdfIsLoading
  //     }
  //     console.log(value)
  //   }
  // },
  methods: {
    async getPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`offer/get-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          // let blob = new Blob([data], { type: 'application/octet-stream' }),  -- DOWNLOAD FILE
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.showFileNotFoundError()
        // this.$toast.add({severity:'error', summary: `404`, detail: 'File not found', life: settings.toastLife})
        // this.showError(err)
      }
      // this.isLoading = false
    },
  }
}
</script>

<style scoped>
.pdf-icon {
  display: block;
}
</style>